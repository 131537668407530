.big {
  font-size: 60px;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 50px;
  margin-top: 100px;
}

.small {
  font-size: 20px;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 35px;
  margin-top: 30px;
}

.boxes {
  text-align: center;
}
.accordion {
  background-color: #eee;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 35%;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 5%;
}
.containers {
  margin: 20px auto;
}

.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: block;
  margin: 1px 10vw;
  overflow: hidden;
  border: gray solid;
  font-family: "Sansita";
  max-height: 500px;
  transition: max-height 1s linear;
}

@keyframes growDown {
  0% {
    height: 5%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}

.panel p {
  padding: 10px 10px;
  text-align: left;
  background-color: white;
}

.listaPaketa {
  color: #015d9d !important;
}

/* .invisible {
  display: none;
} */

.invisible {
  overflow: hidden;
  max-height: 0;
}

.sliderMobContainer {
  display: none;
}

@media only screen and (max-width: 750px) {
  .big {
    font-size: 8vw;
    margin-top: 15px;
  }
  .sliderContainer {
    display: none;
  }
  .sliderMobContainer {
    display: inline-block;
  }
  .accordion {
    width: 100%;
    background: transparent;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 0;
    color: white;
  }
  .accordion:hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    background: transparent;
  }
  .panel {
    width: 100%;
    background-color: white;
    color: black;
    margin: 0;
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
