.footer {
  width: 100%;
  background: rgba(0, 32, 57, 0.9);
  /* background-color: #16678f; */
  display: flex;
  flex-direction: row;
  color: white;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0 15px;
}

.send {
  text-decoration: none;
  color: white;
  text-align: center;
}

.black {
  color: black;
}

@media only screen and (max-width: 550px) {
  .footer {
    display: inline-block;
  }
  .container {
    width: 69%;
  }
}

@media only screen and (max-width: 350px) {
  .container {
    width: 90%;
  }
}
