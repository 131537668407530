.izvjestaji {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-start;
}
.izvjestaji a {
  color: white;
  font-weight: 500;
}
