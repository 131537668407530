.select {
  font-family: "Sansita";
}

.highlight {
  font-family: "Sansita";
  font-weight: 700;
  color: #005d9d;
}

.highlightName {
  font-family: "Sansita";
  font-weight: 800;
  color: #005d9d;
}
.container {
  display: flex;
  flex-direction: column;
  min-height:62vh;
}

.link {
  text-align: center;
  margin: 10px;
  color: white;
  font-weight: 500;
  align-self: center;
}

.tableContainer {
  padding: 10px;
  min-width: 50%;
  max-width: 85%;
  margin: auto;
  font-family: "Sansita";
}

.selectContainer {
  width: 100%;
  margin-bottom: 1px;
  display: flex;
  justify-content: center;
  font-family: "Sansita";
}

.table tr > th {
  font-family: "Sansita";
  font-weight: 700;
  color: #005d9d;
}

tr:nth-child(even) {
  background: #d8f9ff;
}
tr:nth-child(odd) {
  background: #b1f2ff;
}
tr,
td,
th {
  text-align: center !important;
}

@media only screen and (max-width: 480px) {
  .select {
    width: 60%;
  }
}

@media only screen and (max-width: 330px) {
  .select {
    width: 75%;
  }
}
