.small {
  font-size: 20px;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 35px;
  margin-top: 30px;
}

.listaPaketa {
  background: transparent;
  margin: 10px 0;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
}

.paragraph {
  margin-top: 50px;
}

.karte {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-family: "Days One";
}
.imageContainer {
  height: 400px;
  width: 40%;
  margin-left: 30%;
}
.imageContainer img {
  height: 100%;
  width: 100%;
  margin: auto;
}

@media only screen and (max-width: 900px) {
  .imageContainer {
    width: 60%;
    margin: auto;
  }
}
