.white {
  color: white;
  font-family: "Sansita";
  font-weight: 500;
}

.container {
  margin-left: 10px;
  background-color: rgba(18, 67, 114, 0.5);
}

.highlight {
  font-weight: 500;
  color: #3395be;
}

.whiteContainer {
  margin-left: 10px;
  font-weight: 600;
  font-size: 1.3em;
  font-family: "Sansita";
  background-color: #002947;
  color: white;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2em;
}

.redirect:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .white {
    font-size: 10px;
    margin: auto;
  }
}
@media only screen and (max-width: 500px) {
  .whiteContainer > b {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
  }
}
