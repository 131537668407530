.karta {
  margin: 10px;
  box-shadow: 5px 5px 5px grey;
}
.cardButton {
  color: blue;
  border: none;
  background: transparent;
  font-size: 0.5em;
  text-align: center;
  justify-content: center;
}
