.mobContainer {
  display: flex;
  flex-direction: column;
}

.small1 {
  font-size: 4vw;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 35px;
  margin-top: 30px;
  width: 100%;
}
.small2 {
  font-size: 4vw;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 35px;
  margin-top: 30px;
  width: 100%;
}
.small3 {
  font-size: 4vw;
  color: white;
  font-family: "Sansita", serif;
  font-weight: bold;
  text-align: center;
  padding: 35px;
  margin-top: 30px;
  width: 100%;
}

.listaPaketa {
  background: transparent;
  margin: 10px 0;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
}

.paragraph {
  margin-top: 50px;
}

.karte {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  font-family: "Days One";
}
.imageContainer {
  height: 50vw;
  width: 80vw;
  margin: auto;
}
.imageContainer img {
  height: 100%;
  width: 100%;
  margin: auto;
}
