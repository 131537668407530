.App {
  display: flex;
  flex-direction: column;
  background-image: url("images/satelit.png");
  height: auto;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 750px) {
  .App {
    background-image: none;
    background: radial-gradient(#112349, #152853);
    /* background-image: url("../src/images/mobile.png"); */
    /* background-repeat: no-repeat; */
  }
}
